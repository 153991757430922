import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  padding: 0 20px;
  margin-top: 40px;

  @media (max-width: 715px) {
    flex-wrap: wrap;
  }
`
const MobileWrapper = styled(DesktopWrapper)`
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin-left: -10px;
  margin-top: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

const DesktopLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`
const MobileLeftWrapper = styled(DesktopLeftWrapper)`
  flex-direction: column;
`
export const LeftWrapper = isMobile ? MobileLeftWrapper : DesktopLeftWrapper

const DesktopTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  margin-right: 48px;
  gap: 15px;
`
const MobileTitleWrapper = styled(DesktopTitleWrapper)`
  justify-content: center;
  width: 100vw;
  margin-left: 0;
  margin-right: 0;
`
export const TitleWrapper = isMobile ? MobileTitleWrapper : DesktopTitleWrapper

const DesktopSearchWrapper = styled.div`
  min-width: 300px;
  max-width: 520px;
  width: 80vw;
  margin-bottom: 0;
`
const MobileSearchWrapper = styled(DesktopSearchWrapper)`
  margin-bottom: 10px;
  width: 95vw;
`
export const SearchWrapper = isMobile
  ? MobileSearchWrapper
  : DesktopSearchWrapper

const DesktopRightWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  @media (max-width: 715px) {
    margin-top: 15px;
  }
`
const MobileRightWrapper = styled(DesktopRightWrapper)`
  flex-direction: column;
`
export const RightWrapper = isMobile ? MobileRightWrapper : DesktopRightWrapper

export const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ResetOnboardingWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
  margin: -20px 0 15px;
`

export const ActionsButtonWrapper = styled.div`
  width: ${() => (isMobile ? '95vw' : 'auto')};
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
