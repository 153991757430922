import styled from '@emotion/styled'

export const SelectWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

export const MenuWrapper = styled.div<{ desktopLeft?: number }>`
  background: ${({ theme }) => theme.colors.background.surface};
  border-radius: 25px;
  margin-top: 5px;
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  padding: 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  min-width: 270px;
  right: -10px;
  top: 40px;
  z-index: 500;

  ${({ desktopLeft }) => (desktopLeft ? `left: ${desktopLeft}px;` : '')}
`

export const MobileProfileMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding-top: 5px;
`
